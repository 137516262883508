.mcDiaporama {
  height: 100% !important; }
  .mcDiaporama .mcDiaporama-wrapper, .mcDiaporama .bx-wrapper, .mcDiaporama .bx-viewport, .mcDiaporama .mcDiaporama-list, .mcDiaporama .mcDiaporama-item {
    width: 100% !important;
    height: 100% !important;
    z-index: 5; }
  .mcDiaporama-list {
    margin: 0;
    padding: 0; }
  .mcDiaporama-item {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 5;
    color: white; }
    .mcDiaporama-item::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 0; }
    .mcDiaporama-item > div, .mcDiaporama-item > a {
      display: block;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%; }
    .mcDiaporama-item--hasLink a {
      text-decoration: none;
      color: inherit; }
  .mcDiaporama-button {
    display: inline-block;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    background: transparent;
    border: 0.1rem solid white;
    text-decoration: none;
    line-height: 1.4;
    margin: 0.7em 0 .5em;
    padding: 1.6em 2em 1.4em 2.2em;
    font-size: 1.4rem;
    border-radius: 2.5em;
    z-index: 1;
    cursor: pointer;
    transition: all .25s ease-out;
    transition-property: border, background, color;
    position: relative; }
    .mcDiaporama-button::after {
      display: inline-block;
      margin-top: -0.1em;
      content: "\e900";
      font-size: 1.4em;
      vertical-align: middle;
      margin-left: 0.5em;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .mcDiaporama-button:hover {
      background: white;
      color: #222222; }
  .mcDiaporama h2, .mcDiaporama p {
    text-align: center; }
  .mcDiaporama h2 {
    font-size: 5.5rem;
    font-weight: normal;
    font-family: "gattsby", Arial, Tahoma, Verdana, Helvetica, sans-serif;
    line-height: 1.1; }
    @media (max-width: 800px) {
      .mcDiaporama h2 {
        font-size: 3rem; } }
  .mcDiaporama p {
    font-size: 1.6rem;
    letter-spacing: 0.05em; }
  .mcDiaporama .u-wrapper {
    text-align: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15%; }
    @media (max-width: 1024px) {
      .mcDiaporama .u-wrapper {
        top: 50%; } }
    @media (max-width: 800px) {
      .mcDiaporama .u-wrapper {
        padding: 0 10%; } }
  @media (max-width: 640px) {
    .mcDiaporama .bx-controls-direction {
      display: none; } }
  .mcDiaporama .bx-controls-direction .bx-prev,
  .mcDiaporama .bx-controls-direction .bx-next {
    font-size: 0;
    width: 3rem;
    height: 3rem;
    border-left: 0.5rem solid white;
    border-bottom: 0.5rem solid white;
    position: absolute;
    z-index: 10;
    top: 55%;
    opacity: 0.8;
    transition: all .25s ease-out;
    transition-property: border-color, opacity, transform; }
    @media (max-width: 1024px) {
      .mcDiaporama .bx-controls-direction .bx-prev,
      .mcDiaporama .bx-controls-direction .bx-next {
        top: 50%; } }
  .mcDiaporama .bx-controls-direction .bx-prev {
    left: 10vw;
    transform: translateY(-50%) rotateZ(45deg); }
    @media (max-width: 1024px) {
      .mcDiaporama .bx-controls-direction .bx-prev {
        left: 3vw; } }
    .mcDiaporama .bx-controls-direction .bx-prev:hover {
      transform: translateY(-50%) rotateZ(45deg) scale(1.2);
      opacity: 1; }
  .mcDiaporama .bx-controls-direction .bx-next {
    right: 10vw;
    transform: translateY(-50%) rotateZ(-135deg); }
    @media (max-width: 1024px) {
      .mcDiaporama .bx-controls-direction .bx-next {
        right: 3vw; } }
    .mcDiaporama .bx-controls-direction .bx-next:hover {
      transform: translateY(-50%) rotateZ(-135deg) scale(1.2);
      opacity: 1; }
  .mcDiaporama .bx-pager {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 3rem;
    width: 100%;
    z-index: 10; }
    @media (min-width: 641px) {
      .mcDiaporama .bx-pager {
        display: none; } }
    .mcDiaporama .bx-pager-item {
      display: block; }
      .mcDiaporama .bx-pager-item a {
        display: block;
        margin: 0 1.4rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        font-size: 0;
        background: white;
        opacity: 0.5;
        transition: opacity .25s ease-out, transform .25s ease-out; }
        .mcDiaporama .bx-pager-item a.active {
          opacity: 0.9;
          transform: scale(1.2); }
        .mcDiaporama .bx-pager-item a:hover {
          opacity: .7; }
