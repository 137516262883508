.activity-list {
  text-align: left;
  display: flex;
  flex-wrap: wrap; }
  .activity-list-container {
    text-align: center;
    padding: 3rem 0; }
    .activity-list-container-title {
      color: #0072bf;
      text-transform: uppercase;
      padding: 3rem 0; }
      .activity-list-container-title h2 {
        font-family: "gattsby", Arial, Tahoma, Verdana, Helvetica, sans-serif;
        font-size: 3.6rem;
        padding-bottom: 0.4em; }
        @media (max-width: 640px) {
          .activity-list-container-title h2 {
            font-size: 2.8rem; } }
      .activity-list-container-title p {
        font-size: 1.8rem;
        font-weight: 600; }
        @media (max-width: 640px) {
          .activity-list-container-title p {
            font-size: 1.6rem; } }
  .activity-list-link-all {
    display: inline-block;
    text-transform: uppercase;
    color: #222222;
    font-weight: 600;
    background: transparent;
    border: 0.1rem solid #222222;
    text-decoration: none;
    line-height: 1.4;
    margin: 0.7em 0 .5em;
    padding: 1.6em 2em 1.4em 2.2em;
    font-size: 1.4rem;
    border-radius: 2.5em;
    z-index: 1;
    cursor: pointer;
    transition: all .25s ease-out;
    transition-property: border, background, color;
    position: relative; }
    .activity-list-link-all::after {
      display: inline-block;
      margin-top: -0.1em;
      content: "\e900";
      font-size: 1.4em;
      vertical-align: middle;
      margin-left: 0.5em;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .activity-list-link-all:hover {
      background: #222222;
      color: rgba(255, 255, 255, 0.9); }

.activity-item {
  display: inline-block;
  width: 31.5%;
  margin-right: 2.75%;
  margin-bottom: 2.75%;
  vertical-align: top;
  border: .1rem solid #eeeeee; }
  @media (min-width: 1025px) {
    .activity-item:nth-of-type(3n) {
      margin-right: 0; } }
  @media (min-width: 641px) and (max-width: 1024px) {
    .activity-item {
      width: 48.5%;
      margin-right: 3%;
      margin-bottom: 3%; }
      .activity-item:nth-of-type(2n) {
        margin-right: 0; } }
  @media (max-width: 640px) {
    .activity-item {
      max-width: 46.3rem;
      margin: 0 auto 2rem;
      width: 100%;
      display: block; } }
  .activity-item-link {
    overflow: hidden;
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start; }
  .activity-item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28rem;
    overflow: hidden; }
    .activity-item-image img {
      transition: all 0.45s ease-out;
      transition-property: transform; }
      @media (min-width: 1025px) {
        .activity-item-image img {
          transform: scale(0.8); } }
  .activity-item-infos {
    flex-grow: 1;
    display: flex;
    padding: 2.5rem 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center; }
    .activity-item-infos-title {
      color: #222222;
      text-transform: uppercase;
      font-weight: 600;
      position: relative;
      padding: 0 1.5rem;
      text-align: center; }
      .activity-item-infos-title-head, .activity-item-infos-title-sub {
        display: block; }
      .activity-item-infos-title-head {
        font-size: 1.6rem; }
      .activity-item-infos-title-sub {
        font-size: 1.2rem; }
    .activity-item-infos-line {
      content: '';
      width: calc(100% - 6rem);
      height: 0.9px;
      background: #0072bf;
      display: block;
      margin: 1rem auto;
      transition: all 0.45s ease-out;
      transition-property: transform;
      transform: scaleX(0.2); }
    .activity-item-infos-description {
      display: flex;
      justify-content: center;
      color: #999999;
      font-size: 1.4rem;
      padding: 0 3rem; }
    .activity-item-infos-more {
      position: absolute;
      display: none; }
  .activity-item:hover .activity-item-image img {
    transform: scale(1); }
  .activity-item:hover .activity-item-infos-line {
    transform: scaleX(1); }

.pagination {
  text-align: center; }
  .pagination-container {
    padding: 5rem 0 7rem; }
  .pagination-arrow {
    text-decoration: none;
    font-size: 0;
    display: inline-block; }
    .pagination-arrow--disabled {
      opacity: 0.7;
      pointer-events: none; }
    .pagination-arrow::before {
      content: '';
      display: inline-block;
      border-bottom: .5rem solid transparent;
      border-top: .5rem solid transparent; }
    .pagination-arrow--first::after, .pagination-arrow--last::after {
      content: '';
      display: inline-block;
      border-bottom: .5rem solid transparent;
      border-top: .5rem solid transparent; }
    .pagination-arrow--previous, .pagination-arrow--first {
      padding: 0 .5rem 0 0;
      color: #999999;
      transition: color .25s ease-out; }
      .pagination-arrow--previous::before, .pagination-arrow--previous::after, .pagination-arrow--first::before, .pagination-arrow--first::after {
        border-right: .9rem	solid currentColor; }
    .pagination-arrow--next, .pagination-arrow--last {
      padding: 0 0 0 .5rem;
      color: #999999;
      transition: color .25s ease-out; }
      .pagination-arrow--next::before, .pagination-arrow--next::after, .pagination-arrow--last::before, .pagination-arrow--last::after {
        border-left: .9rem	solid currentColor; }
    .pagination-arrow:hover {
      color: #0072bf; }
  .pagination .page-p {
    text-decoration: none;
    padding: .3rem;
    color: #0072bf;
    font-size: 1.6rem;
    transition: color .25s ease-out; }
    .pagination .page-p--on {
      font-weight: 600; }
    .pagination .page-p:not(.page-p--on):hover {
      color: black; }
