h2.titre {
  color: #0072bf;
  margin: .6em 0;
  font-size: 3.6rem;
  line-height: 1.15;
  font-family: "gattsby", Arial, Tahoma, Verdana, Helvetica, sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal; }

h3.soustitre_1 {
  line-height: 1.2em;
  color: #0072bf;
  font-size: 1.8rem;
  margin: .4em 0;
  font-weight: 600;
  text-transform: uppercase; }

h3.soustitre_1_rouge {
  line-height: 1.2em;
  color: #be1e2d;
  font-size: 1.8rem;
  margin: .4em 0;
  font-weight: 600;
  text-transform: uppercase; }

h4.soustitre_2 {
  line-height: 1.2em;
  color: white;
  font-size: 1.8rem;
  margin: .4em 0;
  font-weight: normal; }

h5.intertitre {
  text-transform: uppercase;
  line-height: 1.2em;
  color: #0072bf;
  font-size: 1.6rem;
  margin: .4em 0;
  font-weight: 600; }

strong.accroche {
  font-size: 1.6rem;
  font-weight: 600;
  color: #222222; }

strong.mise_en_avant_1 {
  font-weight: bold;
  color: #0072bf; }

strong.mise_en_avant_2 {
  font-weight: bold;
  color: #be1e2d; }

span.legende {
  font-size: 1.4rem;
  font-style: italic; }

blockquote.citation {
  margin: 1em 0;
  padding: .4em 1em;
  border-left: 0.4rem solid #0072bf;
  background: rgba(0, 0, 0, 0.04); }

strong.big {
  font-size: 1.5em;
  font-weight: 400; }
