.socialLinks {
  display: flex; }
  #zone-socials .socialLinks {
    justify-content: center;
    margin-top: 3rem; }
  .socialLinks, .socialLinks > li {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .socialLinks > li {
    margin: 0 .2rem;
    color: #fff; }
  .socialLinks a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    color: inherit;
    border-radius: 50%;
    text-decoration: none; }
    .socialLinks a i {
      font-size: 2rem; }
    #zone-footer .socialLinks a {
      background: rgba(255, 255, 255, 0.05); }
    #zone-socials .socialLinks a {
      background: white;
      color: #0072bf; }
    .socialLinks a.facebookLink:hover {
      background: #3c5a99 !important; }
    .socialLinks a.twitterLink:hover {
      background: #5ea9dd !important; }
    .socialLinks a.googleLink:hover {
      background: #d74536 !important; }
    .socialLinks a.youtubeLink:hover {
      background: #e62117 !important; }
    .socialLinks a.pinterestLink:hover {
      background: #cb2026 !important; }
    .socialLinks a.viadeoLink:hover {
      background: #f4982b !important; }
    .socialLinks a.linkedinLink:hover {
      background: #1a85bc !important; }
    .socialLinks a:hover {
      color: #fff !important; }
