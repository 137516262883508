.content .search-container {
  width: 100%;
  position: relative;
  background: #0072bf;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13rem;
  color: white; }
  .content .search-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #0072bf;
    z-index: 0; }

.content .search-form {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 1024px) {
    .content .search-form {
      width: 100%;
      max-width: 70rem; } }
  .content .search-form input[type="text"] {
    width: 100%;
    font-size: 1.8rem;
    text-transform: uppercase;
    outline: none;
    color: white;
    background: transparent;
    border: 0.1rem solid white;
    text-decoration: none;
    line-height: 1.1;
    margin: 0.7em 0 .5em;
    padding: 1em 4.5em 0.95em 2em;
    border-radius: 2.5em;
    z-index: 1;
    box-sizing: border-box;
    color: white;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 380px) {
      .content .search-form input[type="text"] {
        padding-right: 2.5em; } }
    .content .search-form input[type="text"]::placeholder {
      font-style: italic;
      color: inherit; }

.content .search-submit {
  font-size: 1rem;
  position: absolute;
  right: 5rem;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  z-index: 20;
  transition: transform .25s ease-out; }
  @media (max-width: 640px) {
    .content .search-submit {
      right: 1rem; } }
  .content .search-submit::before, .content .search-submit::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transition: border-color .25s ease-out; }
  .content .search-submit::before {
    width: 1.6rem;
    height: 1.6rem;
    border: 0.2em solid white;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .content .search-submit::after {
    width: 0.7rem;
    height: .2rem;
    border: .1em solid white;
    transform: translateX(0.7rem) translateX(-50%) translateY(0.7rem) translateY(-50%) rotateZ(45deg); }
  .content .search-submit input[type="submit"] {
    font-size: 0;
    width: 4rem;
    height: 4rem;
    opacity: 0;
    cursor: pointer; }
  .content .search-submit:hover {
    transform: translateY(-50%) scale(1.2); }

.content .search #cs-form-element-searchArtist {
  width: 100%; }

body .ui-menu {
  padding: 0rem 2.5rem;
  background: transparent;
  border: none; }
  body .ui-menu li.ui-menu-item {
    border-right: 0.1rem solid rgba(34, 34, 34, 0.2);
    background: white;
    padding: 0.5rem 1.2rem; }
    body .ui-menu li.ui-menu-item.ui-state-focus,
    body .ui-menu li.ui-menu-item a.ui-state-focus {
      margin: 0;
      color: #0072bf;
      background: white; }
  body .ui-menu li:last-child, body .ui-menu li.ui-menu-divider {
    border-bottom: 0.1rem solid rgba(34, 34, 34, 0.2);
    padding: 0 !important;
    height: 0;
    margin: 0; }
    body .ui-menu li:last-child a, body .ui-menu li.ui-menu-divider a {
      display: none; }
